<template>
  <div class="main_style">
    <NavBarHome v-if="navBarStyle === '1'" textColor="#000000" :iconUrl="require('@/assets/img/home/logo-2.png')"/>
    <NavBar v-else />
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享联盟链治理平台</p>
        <p class="header-desc">
          众享联盟链治理平台是众享比特基于Fabric研发的一组套件，可以有效解决Fabric框架本身配置复杂、使用繁琐的问题。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">产品介绍</div>
      <div class="intro-content">
        <p>
          众享联盟链治理平台是众享科技基于Fabric研发的一组套件，可以有效解决Fabric框架本身配置复杂、使用繁琐的问题。众享联盟链治理平台在Fabric之上引入了“公共仓库（公共基础链）”和“流程引擎”，使得用户对通道、机构、合约的操作及管理更便捷。通过部署、使用众享联盟治理服务，联盟机构可以方便地管理联盟链及联盟的配置信息。
        </p>
      </div>
    </div>
    <!-- 核心功能 -->
    <div class="function">
      <div class="commom-title">核心功能</div>
      <div class="content">
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/function-1.png"
                />
              </div>
              <div class="title">Fabric联盟治理</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/function-2.png"
                />
              </div>
              <div class="title">通道管理</div>
            </div>
          </el-col>
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/function-3.png"
                />
              </div>
              <div class="title">合约管理</div>
            </div>
          </el-col>
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/function-4.png"
                />
              </div>
              <div class="title">流程管理</div>
            </div>
          </el-col>
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/function-5.png"
                />
              </div>
              <div class="title">机构管理</div>
            </div>
          </el-col>
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/function-6.png"
                />
              </div>
              <div class="title">公共仓库</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">产品架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/product/Governance/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">产品优势</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/advantage-1.png"
                />
              </div>
              <div class="title">成员管理更灵活</div>
              <div class="text">
                提供比Fabric更加丰富的成员权限，新增机构由联盟成员共同审核，机构信息的变更需要由联盟成员投票决议。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/advantage-2.png"
                />
              </div>
              <div class="title">合约代码自动部署</div>
              <div class="text">
                联盟成员上传的智能合约存储在公共基础链，核心成员在需操作的通道内选择已上传的智能合约部署或升级，合约代码一次上传，处处部署。
联盟治理中，投票通过的部署或升级合约流程，可自动执行合约部署。
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/advantage-3.png"
                />
              </div>
              <div class="title">流程引擎支撑联盟决策</div>
              <div class="text">
                流程策略决定了联盟内的事务需要由核心机构共同参与决策。应用流程管理，有效避免了联盟内少数机构作恶的可能性；所有流程信息，同一通道内成员均可见。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 相关项目 -->
    <div class="project">
      <div class="commom-title">相关项目</div>
      <div class="project-content">
        <el-row :gutter="24">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/project-1.png"
                />
              </div>
              <div class="title">联通交易保障平台</div>
            </div></el-col
          >
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Governance/project-2.png"
                />
              </div>
              <div class="title">众享区块链超级工作站</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import {scrollMixin} from '@/mixins/scroll.js'
export default {
  mixins: [scrollMixin],
  components: {
    NavBar,
    NavBarHome,
    Footer,
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/product/Governance/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    padding-bottom: 70px;
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .function {
    background: #f4f7fc;
    .content {
      @extend .content-width;
      padding-bottom: 20px;
      .line-title {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        div:nth-child(2) {
          padding: 0px 77px;
          font-size: 26px;
          color: #0075ff;
          line-height: 37px;
        }
        div:nth-child(1),
        div:nth-child(3) {
          flex: 1;
          height: 1px;
          background: #0075ff;
        }
      }
      .grid {
        padding-bottom: 26px;
        .grid-content {
          height: 352px;
          margin-bottom: 24px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 42px 24px 0px;
          .img {
            width: 124px;
            height: 124px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            padding: 24px 0px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: rgba(0, 0, 0, 0.85);
            line-height: 30px;
          }
          .text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
  .project {
    .project-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 194px;
        background: #f4f7fc;
        display: flex;
        flex-direction: column;
        align-items: center;
				padding:24px 48px 0px;
        .img {
          width: 66px;
          height: 66px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 22px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
					text-align: center;
        }
      }
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
  }
}
</style>
